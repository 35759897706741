import React from 'react'
import { navigate } from 'gatsby'

import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import Meta from 'components/meta'
import Wrapper from 'components/wrapper'

export default function PortfolioPage({ location }) {
  return (
    <Wrapper location={location} bodyClass="bg-body-darker" wrapperClass="w-100 mx-auto">
      <Meta title="Portfolio" />
      <Row className="text-center">
        <Col>
          <Card className="w-50 mx-auto border-0">
            <Card.Header className="w-100 mb-3">
              <div className="mx-auto text-primary fs-4">Portfolio</div>
            </Card.Header>
            <Row className="g-2 justify-content-center">
              <Col xs="auto">
                <Button
                  variant="outline-primary"
                  onClick={() => navigate('/portfolio/work-galleries')}
                >
                  Galleries
                </Button>
              </Col>
              <Col xs="auto">
                <Button
                  variant="outline-primary"
                  onClick={() => navigate('/portfolio/work-videos')}
                >
                  Videos
                </Button>
              </Col>
              <Col xs="auto">
                <Button
                  variant="outline-primary"
                  onClick={() => navigate('/portfolio/all-works')}
                >
                  Works
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Wrapper>
  )
}
